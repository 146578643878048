import React from 'react'
import { useForm } from 'react-hook-form'

const Contact = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const onSubmit = (data, e) => {
    e.target.reset()
    alert('Message submitted: ' + JSON.stringify(data))
  }

  return (
    <>
      <form name='contact' method='post'>
        <input type='hidden' name='form-name' value='contact' />
        <div className='row'>
          <div className='col-md-6'>
            <div className='form-group mb-3'>
              <input
                name='contact'
                type='text'
                className='form-control theme-light'
                placeholder='Full name'
                {...register('name', { required: true })}
              />
              {errors.name && errors.name.type === 'required' && (
                <span className='invalid-feedback'>Name is required</span>
              )}
            </div>
          </div>
          {/* End .col-6 */}

          <div className='col-md-6'>
            <div className='form-group mb-3'>
              <input
                name='contact'
                type='email'
                className='form-control theme-light'
                placeholder='Email address'
                {...register(
                  'email',
                  {
                    required: 'Email is Required',
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: 'Entered value does not match email format',
                    },
                  },
                  { required: true }
                )}
              />
              {errors.email && (
                <span className='invalid-feedback'>{errors.email.message}</span>
              )}
            </div>
          </div>
          {/* End .col-6 */}

          <div className='col-12'>
            <div className='form-group mb-3'>
              <input
                type='text'
                name='contact'
                className='form-control theme-light'
                placeholder='Subject'
                {...register('subject', { required: true })}
              />
              {errors.subject && (
                <span className='invalid-feedback'>Subject is required.</span>
              )}
            </div>
          </div>
          {/* End .col-12 */}

          <div className='col-12'>
            <div className='form-group mb-3'>
              <textarea
                rows='4'
                name='contact'
                className='form-control theme-light'
                placeholder='Type comment'
                {...register('comment', { required: true })}
              ></textarea>
              {errors.comment && (
                <span className='invalid-feedback'>Comment is required.</span>
              )}
            </div>
          </div>
          {/* End .col-12 */}

          <div className='col-12'>
            <div className='btn-bar'>
              <p>
                <input
                  className='px-btn px-btn-white'
                  type='submit'
                  value='Submit message'
                />
              </p>
            </div>
          </div>
          {/* End .col-12 */}
        </div>
      </form>
    </>
  )
}

export default Contact
